<template>
  <div>
    <analytics-report-toast />

    <slot name="sidebar">
      <asterix-sidebar v-model="openSidebar" />
    </slot>

    <slot name="header">
      <asterix-topbar class="z-50" @change="openSidebar = !openSidebar" />
    </slot>

    <app-blocked v-if="applyToBlocked" :title="appConfig['title']" :message="appConfig['message']" />

    <div id="layout" class="relative md:pl-48">
      <section class="body-layout mx-auto py-4 md:py-6 px-6 md:px-12 pb-56px">
        <slot name="main">
          <router-view />
        </slot>
      </section>

      <slot name="footer">
        <sun-footer class="bg-gray-100 text-black px-8" color="transparent" text-color="black" variant="absolute">
          <div class="w-full flex flex-row justify-between items-center ml-12">
            <p class="text-xs">&copy;{{ reservedText }}</p>
            <p class="text-xs">v{{ version }}</p>
          </div>
        </sun-footer>
      </slot>
    </div>
    <platform-status-modal v-if="isThirdParty && userSettings" :prop-settings="userSettings" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AsterixTopbar from '@/components/molecules/shared/AsterixTopbar';
import AsterixSidebar from '@/components/molecules/shared/AsterixSidebar/AsterixSidebar';
import AnalyticsReportToast from '@/components/molecules/modules/Analytics/AnalyticsReportToast/AnalyticsReportToast';
import { YEAR } from '@/utils/dateTime/year';
import { COMMON_TEXTS } from '@/i18n/commonTexts';
import PlatformStatusModal from '@/components/organisms/modules/thirdParty/platform/PlatformStatusModal.vue';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { CONTEXTS } from '@/model/shared/contexts';
import { GET_USER_SETTINGS_REQUEST, USER_SETTINGS } from '@/store/modules/settings/keys';
import { APP_CONFIG } from '@/store/modules/appConfig/keys';
import AppBlocked from '@/components/organisms/shared/appBlocked/AppBlocked.vue';

export default {
  name: 'AsterixApplicationLayout',
  components: {
    AppBlocked,
    PlatformStatusModal,
    AsterixTopbar,
    AsterixSidebar,
    AnalyticsReportToast,
  },
  data: () => ({
    version: process.env.VUE_APP_VERSION,
    openSidebar: false,
  }),
  computed: {
    ...mapGetters({
      activeContext: ACTIVE_CONTEXT,
      userSettings: USER_SETTINGS,
      appConfig: APP_CONFIG,
    }),
    isThirdParty() {
      return this.activeContext.id === CONTEXTS.THIRD_PARTY.id;
    },
    isDashboardSSP() {
      return this.activeContext.id === CONTEXTS.DASHBOARD_SSP.id;
    },
    reservedText() {
      return `${YEAR} ${COMMON_TEXTS.en.rightsReservedText}`;
    },
    applyToBlocked() {
      return this.appConfig && (this.isThirdParty || this.isDashboardSSP);
    },
  },
  created() {
    this.initValues();
  },
  methods: {
    ...mapActions({
      getUserSettings: GET_USER_SETTINGS_REQUEST,
    }),
    async initValues() {
      if (this.isThirdParty) {
        await this.getUserSettings();
      }
    },
  },
};
</script>

<style scoped>
.pb-56px {
  padding-bottom: 56px;
}

#layout {
  /* height of application header 56px */
  min-height: calc(100vh - 56px);
}
</style>
