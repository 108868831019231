var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("wait-toast", {
    attrs: { status: _vm.toastStatus, value: _vm.hasToast },
    on: {
      close: function ($event) {
        return _vm.closeReportToast()
      },
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function ({}) {
          return [
            _vm.isLoading
              ? _c("p", { staticClass: "text-gray-600 text-left" }, [
                  _vm._v(
                    " The report is being generated for " +
                      _vm._s(_vm.clientName) +
                      " (" +
                      _vm._s(_vm.contextName) +
                      "), please wait. "
                  ),
                ])
              : [
                  _c("p", { staticClass: "text-gray-600 text-left" }, [
                    _vm._v(
                      " Requested analytics report for " +
                        _vm._s(_vm.clientName) +
                        " (" +
                        _vm._s(_vm.contextName) +
                        ") is ready. "
                    ),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass:
                        "flex w-full min-h-full justify-start items-center text-gray-700 font-bold relative bottom-0 text-sm",
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "p-2 cursor-pointer text-orange-600",
                          class: {
                            "cursor-not-allowed": _vm.isFileDownloading,
                          },
                          on: {
                            "~click": function ($event) {
                              $event.preventDefault()
                              return _vm.downloadReport()
                            },
                          },
                        },
                        [_vm._v(" Download Report ")]
                      ),
                    ]
                  ),
                ],
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }