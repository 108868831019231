var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("analytics-report-toast"),
      _vm._t("sidebar", function () {
        return [
          _c("asterix-sidebar", {
            model: {
              value: _vm.openSidebar,
              callback: function ($$v) {
                _vm.openSidebar = $$v
              },
              expression: "openSidebar",
            },
          }),
        ]
      }),
      _vm._t("header", function () {
        return [
          _c("asterix-topbar", {
            staticClass: "z-50",
            on: {
              change: function ($event) {
                _vm.openSidebar = !_vm.openSidebar
              },
            },
          }),
        ]
      }),
      _vm.applyToBlocked
        ? _c("app-blocked", {
            attrs: {
              title: _vm.appConfig["title"],
              message: _vm.appConfig["message"],
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "relative md:pl-48", attrs: { id: "layout" } },
        [
          _c(
            "section",
            {
              staticClass:
                "body-layout mx-auto py-4 md:py-6 px-6 md:px-12 pb-56px",
            },
            [
              _vm._t("main", function () {
                return [_c("router-view")]
              }),
            ],
            2
          ),
          _vm._t("footer", function () {
            return [
              _c(
                "sun-footer",
                {
                  staticClass: "bg-gray-100 text-black px-8",
                  attrs: {
                    color: "transparent",
                    "text-color": "black",
                    variant: "absolute",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-full flex flex-row justify-between items-center ml-12",
                    },
                    [
                      _c("p", { staticClass: "text-xs" }, [
                        _vm._v("©" + _vm._s(_vm.reservedText)),
                      ]),
                      _c("p", { staticClass: "text-xs" }, [
                        _vm._v("v" + _vm._s(_vm.version)),
                      ]),
                    ]
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
      _vm.isThirdParty && _vm.userSettings
        ? _c("platform-status-modal", {
            attrs: { "prop-settings": _vm.userSettings },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }