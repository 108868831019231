<template>
  <sun-sidebar id="sidebar" v-model="state" position="fixed" z-index="40">
    <div
      :class="`py-4 flex items-center md:justify-center bg-white text-orange-600 leading-tight md:text-white md:bg-${environmentColor}-600 md:rounded-b-xl`"
    >
      <template v-if="!!contextBoardView">
        <router-link :to="contextBoardView">
          <custom-brand-img v-if="customDomain" :type="customImgTypes.BG_DARK" img-class="custom-brand" is-icon />
          <sun-media-icon-light v-else class="w-6 h-6 ml-3 md:ml-1" />
        </router-link>
        <span class="mx-2 md:mx-1 text-gray-600 md:text-white">|</span>
      </template>
      <h1 v-if="contextSelected" class="leading-6 font-bold truncate ml-1" :class="titleClass">{{ title }}</h1>
    </div>
    <div class="mt-4 h-full overflow-y-auto pb-20">
      <template v-if="isLoadedUser">
        <div
          v-if="canShowClientSelector || isEcommercePublisher"
          class="flex justify-center w-full"
          data-testid="client-selector"
        >
          <client-selector :can-go-to-context-board="canGoToContextBoard" />
        </div>
        <div v-else class="px-12 flex justify-center">
          <div v-if="activeClient" class="bg-gray-200 p-2 font-bold text-gray-700 text-sm text-center w-full rounded">
            {{ activeClient.name }}
          </div>
        </div>

        <slot></slot>
      </template>
      <sidebar-loader v-else />
    </div>
  </sun-sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { management } from '@/model/shared/userActions';
import { PERMISSIONS, USER } from '@/store/modules/auth/keys';
import { ACTIVE_CLIENT, ACTIVE_CONTEXT, RESET_CONTEXT } from '@/store/modules/context/keys';
import { CREATE_TOAST } from '@/store/modules/toast/keys.js';
import { ROLES } from '@/model/shared/roles';
import { CONTEXTS } from '@/model/shared/contexts';
import SunMediaIconLight from '@/components/icons/SunMediaIconLight';
import { isPreproduction, isStaging } from '@/utils/environments';
import { isCustomDomain, customImgTypes } from '@/utils/domain';
import CustomBrandImg from '@/components/atoms/CustomBrandImg.vue';
import { workspace as workspaceRouter } from '@/router/private/modules/core/workspace.js';
import getDefaultRouterNameByUser from '@/model/shared/context/getDefaultRouterByUser';

export default {
  name: 'AsterixSidebar',
  components: {
    SunMediaIconLight,
    CustomBrandImg,
    ClientSelector: () => import('../../../atoms/ClientSelector/ClientSelector'),
    SidebarLoader: () => import('./SidebarLoader'),
  },
  props: {
    value: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      state: null,
      customDomain: isCustomDomain(),
      customImgTypes,
      fullDataUser: null,
    };
  },
  computed: {
    ...mapGetters({
      user: USER,
      contextSelected: ACTIVE_CONTEXT,
      permissions: PERMISSIONS,
      activeClient: ACTIVE_CLIENT,
    }),
    contextBoardView() {
      if (this.isCoreAdmin) {
        return { name: workspaceRouter.name };
      }
      const userContext = this.user?.contextRoles.find(contextRole => contextRole.context !== CONTEXTS.CORE.id);
      if (userContext) {
        return {
          name: getDefaultRouterNameByUser(CONTEXTS[userContext.context], this.user),
        };
      }

      return null;
    },
    isLoadedUser() {
      return !!this.activeClient && this.permissions && Object.keys(this.permissions).length > 0;
    },
    canShowClientSelector() {
      return (
        (this.rolePermission.role === ROLES.ADMIN.id ||
          this.rolePermission.role === ROLES.FINANCE.id ||
          this.rolePermission.role === ROLES.CLIENT.id ||
          this.rolePermission.role === ROLES.SELLER.id ||
          this.rolePermission.role === ROLES.AD_OPS.id) &&
        this.canSelectClients
      );
    },
    canGoToContextBoard() {
      /* Roles Client and ADOPS are not allowed to go to context board */
      return this.isCoreAdmin && this.canSelectClients;
    },
    canSelectClients() {
      return this?.permissions?.SHOW_LIST?.includes(management.client.name);
    },
    rolePermission() {
      return this.user?.contextRoles.find(permissions => permissions.context === this.contextSelected.id);
    },
    isCoreAdmin() {
      return this.user?.contextRoles.find(cr => cr.context === CONTEXTS.CORE.id && cr.role === ROLES.ADMIN.id);
    },
    environmentColor() {
      if (isPreproduction) return 'green';
      if (isStaging) return 'blue';
      return 'orange';
    },
    environmentName() {
      if (isPreproduction) return 'PRE';
      if (isStaging) return 'TEST';
      return '';
    },
    title() {
      return this.contextSelected.shortName || this.contextSelected.name;
    },
    titleClass() {
      return this.title.length >= 15 ? 'text-xs' : '';
    },
    isEcommercePublisher() {
      return !!this.user.contextRoles.find(
        contextRole => contextRole.context === CONTEXTS.ECOMMERCE.id && contextRole.role === ROLES.PUBLISHER.id
      );
    },
  },
  watch: {
    value(newVal) {
      this.state = newVal;
    },
    state(newVal) {
      this.$emit('input', newVal);
    },
  },
  created() {
    this.state = this.value;
  },
  methods: {
    ...mapActions({
      resetContext: RESET_CONTEXT,
      createToast: CREATE_TOAST,
    }),
    emitClose() {
      this.$emit('input', false);
    },
    removeClientAndContext() {
      this.resetContext();
    },
  },
};
</script>

<style scoped>
::v-deep #sidebar {
  @apply bg-transparent border-0 z-40 overflow-hidden;
}

::v-deep #sidebar > div:nth-child(2) {
  @apply h-full;
}

@media (max-width: 767px) {
  ::v-deep #sidebar {
    @apply bg-gray-200;
  }

  ::v-deep #sidebar .mt-16 {
    @apply mt-0;
  }

  ::v-deep #sidebar > .absolute.left-0 {
    @apply left-auto right-0 mr-4;
  }
}
.logo {
  color: #545353;
}

::v-deep .active-item {
  @apply bg-gray-200;
}
::v-deep .active-item .sidebar-item-content {
  @apply text-orange-500;
}

::v-deep .sidebar-item-content .active-marker {
  @apply bg-orange-500;
}
.custom-brand {
  max-height: 22px;
}
</style>
