var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "sun-topbar",
        {
          attrs: {
            id: "asterix-topbar",
            variant: "relative",
            color: "transparent",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "flex items-center justify-between w-full relative",
            },
            [
              _c("div", { staticClass: "flex md:hidden items-center flex-1" }, [
                _c(
                  "div",
                  {
                    staticClass: "cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("change")
                      },
                    },
                  },
                  [_c("menu-svg", { staticClass: "text-gray-800 h-6" })],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "flex flex-1 items-center justify-center" },
                  [
                    _vm.customDomain
                      ? _c("custom-brand-img", {
                          attrs: {
                            type: _vm.customImgTypes.BG_DARK,
                            "img-class": "custom-brand w-40",
                          },
                        })
                      : _c("sun-media-logo-light", { staticClass: "w-40" }),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "relative flex justify-end md:flex-1" },
                [
                  _c(
                    "sun-button",
                    {
                      staticClass: "rounded-full h-6 w-6 border border-white",
                      attrs: { id: "user-button", color: "transparent" },
                      on: {
                        click: function ($event) {
                          _vm.isOpen = !_vm.isOpen
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [_c("user-svg", { staticClass: "h-3 w-3" })],
                        1
                      ),
                    ]
                  ),
                  _vm.isOpen
                    ? _c("div", {
                        staticClass: "fixed inset-0",
                        attrs: { tabindex: "-1" },
                        on: {
                          click: function ($event) {
                            _vm.isOpen = false
                          },
                        },
                      })
                    : _vm._e(),
                  _c("transition", { attrs: { name: "fade" } }, [
                    _vm.isOpen
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "mt-2 absolute right-0 text-left text-gray-800 mr-auto z-20",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "w-64 bg-white rounded-lg shadow-lg z-20",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "flex items-center px-6 py-4",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "flex" },
                                      [
                                        _c("user-svg", {
                                          staticClass: "h-6 w-6 text-gray-600",
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "ml-4 flex flex-grow flex-col max-w-full truncate",
                                      },
                                      [
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "font-semibold text-gray-800 cursor-default leading-none h-4 overflow-hidden truncate whitespace-normal",
                                            attrs: {
                                              title: _vm.user
                                                ? _vm.user.name
                                                : "Loading...",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.user && _vm.user
                                                    ? _vm.user.name
                                                    : "Loading..."
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm.user
                                          ? _c(
                                              "p",
                                              [
                                                _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "text-sm text-gray-600 leading-none hover:underline",
                                                    attrs: {
                                                      to: {
                                                        name: _vm.routeProfile,
                                                      },
                                                    },
                                                    nativeOn: {
                                                      click: function ($event) {
                                                        _vm.isOpen = false
                                                      },
                                                    },
                                                  },
                                                  [_vm._v(" View Profile ")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "border-t-2 border-gray-200 py-1",
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "block px-6 py-3 leading-tight hover:bg-gray-200 cursor-pointer",
                                        on: {
                                          click: function ($event) {
                                            return _vm.logOut()
                                          },
                                        },
                                      },
                                      [_vm._v(" Sign out ")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                  _vm.isOpen
                    ? _c("div", {
                        staticClass: "fixed inset-0 z-10",
                        attrs: { tabindex: "-1" },
                        on: {
                          click: function ($event) {
                            _vm.isOpen = false
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }