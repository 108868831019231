var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "app-blocked-info rounded-lg p-3 shadow-md",
      class: _vm.classByType,
    },
    [
      _c(
        "div",
        { staticClass: "flex flex-col justify-between" },
        [
          _c(
            "div",
            { staticClass: "flex justify-center w-full" },
            [_c("sun-media-icon-dark", { staticClass: "w-32 h-32" })],
            1
          ),
          _c("div", { staticClass: "font-bold" }, [_vm._v(_vm._s(_vm.title))]),
          _c("div", [_vm._v(_vm._s(_vm.message))]),
          _c(
            "sun-button",
            {
              staticClass: "mt-3",
              on: {
                click: function ($event) {
                  return _vm.reloadPage()
                },
              },
            },
            [_vm._v("Reload")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }