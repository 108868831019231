var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "flex justify-between rounded-lg p-3 shadow-md",
      class: _vm.classByType,
    },
    [
      _c("div", { staticClass: "text-left w-full" }, [
        _c("div", { staticClass: "flex flex-row" }, [
          _c("table", { staticClass: "w-full" }, [
            _vm.platformsDone.length
              ? _c("tr", [
                  _c("td", { staticClass: "font-bold text-2xl pr-2" }, [
                    _vm._v("Done"),
                  ]),
                  _c("td", { staticClass: "w-full" }, [
                    _vm._v(_vm._s(_vm.arrayToString(_vm.platformsDone))),
                  ]),
                ])
              : _vm._e(),
            _vm.platformsWaiting.length
              ? _c("tr", [
                  _c("td", { staticClass: "font-bold text-2xl pr-2" }, [
                    _vm._v("Waiting"),
                  ]),
                  _c("td", { staticClass: "w-full" }, [
                    _vm._v(_vm._s(_vm.arrayToString(_vm.platformsWaiting))),
                  ]),
                ])
              : _vm._e(),
            _vm.platformsError.length
              ? _c("tr", [
                  _c("td", { staticClass: "font-bold text-2xl pr-2" }, [
                    _vm._v("Error"),
                  ]),
                  _c("td", { staticClass: "w-full" }, [
                    _vm._v(_vm._s(_vm.arrayToString(_vm.platformsError))),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm.dismissible
        ? _c(
            "div",
            {
              staticClass: "py-1",
              attrs: { "aria-hidden": "true" },
              on: {
                click: function ($event) {
                  return _vm.$emit("click")
                },
              },
            },
            [
              _c(
                "svg",
                {
                  staticClass: "cursor-pointer",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    "xmlns:xlink": "http://www.w3.org/1999/xlink",
                    width: "1em",
                    height: "1em",
                    viewBox: "0 0 24 24",
                  },
                },
                [
                  _c("path", {
                    staticClass: "fill-current",
                    attrs: {
                      d: "M16.192 6.344l-4.243 4.242l-4.242-4.242l-1.414 1.414L10.535\n              12l-4.242 4.242l1.414 1.414l4.242-4.242l4.243 4.242l1.414-1.414L13.364\n              12l4.242-4.242z",
                    },
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }