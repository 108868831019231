<template>
  <div>
    <sun-topbar id="asterix-topbar" variant="relative" color="transparent">
      <div class="flex items-center justify-between w-full relative">
        <div class="flex md:hidden items-center flex-1">
          <div class="cursor-pointer" @click="$emit('change')">
            <menu-svg class="text-gray-800 h-6" />
          </div>
          <div class="flex flex-1 items-center justify-center">
            <custom-brand-img v-if="customDomain" :type="customImgTypes.BG_DARK" img-class="custom-brand w-40" />
            <sun-media-logo-light v-else class="w-40" />
          </div>
        </div>
        <div class="relative flex justify-end md:flex-1">
          <sun-button
            id="user-button"
            class="rounded-full h-6 w-6 border border-white"
            color="transparent"
            @click="isOpen = !isOpen"
          >
            <div class="flex items-center">
              <user-svg class="h-3 w-3" />
            </div>
          </sun-button>
          <div v-if="isOpen" class="fixed inset-0" tabindex="-1" @click="isOpen = false" />
          <transition name="fade">
            <div v-if="isOpen" class="mt-2 absolute right-0 text-left text-gray-800 mr-auto z-20">
              <div class="w-64 bg-white rounded-lg shadow-lg z-20">
                <div class="flex items-center px-6 py-4">
                  <div class="flex">
                    <user-svg class="h-6 w-6 text-gray-600" />
                  </div>
                  <div class="ml-4 flex flex-grow flex-col max-w-full truncate">
                    <p
                      class="font-semibold text-gray-800 cursor-default leading-none h-4 overflow-hidden truncate whitespace-normal"
                      :title="user ? user.name : 'Loading...'"
                    >
                      {{ user && user ? user.name : 'Loading...' }}
                    </p>
                    <p v-if="user">
                      <router-link
                        class="text-sm text-gray-600 leading-none hover:underline"
                        :to="{ name: routeProfile }"
                        @click.native="isOpen = false"
                      >
                        View Profile
                      </router-link>
                    </p>
                  </div>
                </div>
                <div class="border-t-2 border-gray-200 py-1">
                  <span class="block px-6 py-3 leading-tight hover:bg-gray-200 cursor-pointer" @click="logOut()">
                    Sign out
                  </span>
                </div>
              </div>
            </div>
          </transition>
          <div v-if="isOpen" class="fixed inset-0 z-10" tabindex="-1" @click="isOpen = false" />
        </div>
      </div>
    </sun-topbar>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AUTH_LOGOUT, USER } from '@/store/modules/auth/keys';
import { ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import { isCustomDomain, customImgTypes } from '@/utils/domain';
import CustomBrandImg from '@/components/atoms/CustomBrandImg.vue';

export default {
  name: 'AsterixTopbar',
  components: {
    CustomBrandImg,
    UserSvg: () => import('@/components/icons/UserSvg'),
    MenuSvg: () => import('@/components/icons/MenuSvg'),
    SunMediaLogoLight: () => import('@/components/icons/SunMediaLogoLight'),
  },
  data() {
    return {
      isOpen: false,
      customDomain: isCustomDomain(),
      customImgTypes,
    };
  },
  computed: {
    ...mapGetters({
      user: [USER],
      contextActive: ACTIVE_CONTEXT,
    }),
    routeProfile() {
      return `${this.contextActive.baseUrl}-profile`;
    },
  },
  methods: {
    ...mapActions([AUTH_LOGOUT]),
    async logOut() {
      await this[AUTH_LOGOUT]();
    },
  },
};
</script>
<style scoped>
#asterix-topbar {
  @apply bg-orange-600 rounded-b-xl;
}

@media (min-width: 640px) {
  #asterix-topbar {
    @apply px-6;
  }
}

#user-button {
  @apply p-0;
}

.icon-menu {
  width: 30px;
  height: auto;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.custom-brand {
  max-height: 45px;
}
</style>
