<template>
  <div class="flex justify-between rounded-lg p-3 shadow-md" :class="classByType">
    <div class="text-left w-full">
      <div class="flex flex-row">
        <table class="w-full">
          <tr v-if="platformsDone.length">
            <td class="font-bold text-2xl pr-2">Done</td>
            <td class="w-full">{{ arrayToString(platformsDone) }}</td>
          </tr>
          <tr v-if="platformsWaiting.length">
            <td class="font-bold text-2xl pr-2">Waiting</td>
            <td class="w-full">{{ arrayToString(platformsWaiting) }}</td>
          </tr>
          <tr v-if="platformsError.length">
            <td class="font-bold text-2xl pr-2">Error</td>
            <td class="w-full">{{ arrayToString(platformsError) }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div v-if="dismissible" class="py-1" aria-hidden="true" @click="$emit('click')">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="1em"
        height="1em"
        class="cursor-pointer"
        viewBox="0 0 24 24"
      >
        <path
          d="M16.192 6.344l-4.243 4.242l-4.242-4.242l-1.414 1.414L10.535
                12l-4.242 4.242l1.414 1.414l4.242-4.242l4.243 4.242l1.414-1.414L13.364
                12l4.242-4.242z"
          class="fill-current"
        />
      </svg>
    </div>
  </div>
</template>
<script>
import classColors from '@sunmedia/sun-ui/src/utils/classColors';

export default {
  name: 'PlatformListStatusAlert',
  props: {
    dismissible: {
      type: Boolean,
      default: () => true,
    },
    variant: {
      type: String,
      default: () => 'warn',
    },
    platformsDone: {
      type: Array,
      default: () => [],
    },
    platformsWaiting: {
      type: Array,
      default: () => [],
    },
    platformsError: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    classByType() {
      return `${classColors('bg', 'yellow', 200)} ${classColors('text', 'yellow', 900)}`;
    },
  },
  methods: {
    arrayToString(array) {
      return array.join(', ');
    },
  },
};
</script>

<style scoped>
:deep(.py-64) {
  padding: 0;
}
:deep(.w-32) {
  width: 5rem;
}
:deep(.h-32) {
  height: 5rem;
}
:deep(.spinner) {
  border-width: 5px;
}
</style>
