var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _vm.showAlert
      ? _c(
          "div",
          { staticClass: "platform-status-modal" },
          [
            _c("platform-list-status-alert", {
              attrs: {
                variant: "warn",
                "platforms-done": _vm.platformsDone,
                "platforms-waiting": _vm.platformsWaiting,
                "platforms-error": _vm.platformsError,
              },
              on: { click: _vm.close },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }