var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sun-sidebar",
    {
      attrs: { id: "sidebar", position: "fixed", "z-index": "40" },
      model: {
        value: _vm.state,
        callback: function ($$v) {
          _vm.state = $$v
        },
        expression: "state",
      },
    },
    [
      _c(
        "div",
        {
          class: `py-4 flex items-center md:justify-center bg-white text-orange-600 leading-tight md:text-white md:bg-${_vm.environmentColor}-600 md:rounded-b-xl`,
        },
        [
          !!_vm.contextBoardView
            ? [
                _c(
                  "router-link",
                  { attrs: { to: _vm.contextBoardView } },
                  [
                    _vm.customDomain
                      ? _c("custom-brand-img", {
                          attrs: {
                            type: _vm.customImgTypes.BG_DARK,
                            "img-class": "custom-brand",
                            "is-icon": "",
                          },
                        })
                      : _c("sun-media-icon-light", {
                          staticClass: "w-6 h-6 ml-3 md:ml-1",
                        }),
                  ],
                  1
                ),
                _c(
                  "span",
                  { staticClass: "mx-2 md:mx-1 text-gray-600 md:text-white" },
                  [_vm._v("|")]
                ),
              ]
            : _vm._e(),
          _vm.contextSelected
            ? _c(
                "h1",
                {
                  staticClass: "leading-6 font-bold truncate ml-1",
                  class: _vm.titleClass,
                },
                [_vm._v(_vm._s(_vm.title))]
              )
            : _vm._e(),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "mt-4 h-full overflow-y-auto pb-20" },
        [
          _vm.isLoadedUser
            ? [
                _vm.canShowClientSelector || _vm.isEcommercePublisher
                  ? _c(
                      "div",
                      {
                        staticClass: "flex justify-center w-full",
                        attrs: { "data-testid": "client-selector" },
                      },
                      [
                        _c("client-selector", {
                          attrs: {
                            "can-go-to-context-board": _vm.canGoToContextBoard,
                          },
                        }),
                      ],
                      1
                    )
                  : _c("div", { staticClass: "px-12 flex justify-center" }, [
                      _vm.activeClient
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "bg-gray-200 p-2 font-bold text-gray-700 text-sm text-center w-full rounded",
                            },
                            [_vm._v(" " + _vm._s(_vm.activeClient.name) + " ")]
                          )
                        : _vm._e(),
                    ]),
                _vm._t("default"),
              ]
            : _c("sidebar-loader"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }