var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasPermission
    ? _c(
        "sun-sidebar-item",
        {
          attrs: {
            active: _vm.getState,
            "force-children-state": _vm.getChildrenState(),
          },
          scopedSlots: _vm._u(
            [
              _vm.hasChildren
                ? {
                    key: "children",
                    fn: function () {
                      return [_vm._t("children")]
                    },
                    proxy: true,
                  }
                : null,
            ],
            null,
            true
          ),
        },
        [
          _c(
            "router-link",
            {
              attrs: { to: _vm.to },
              nativeOn: {
                "!click": function ($event) {
                  return _vm.handleClick.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("div", { staticClass: "mr-2" }, [_vm._t("icon")], 2),
                  _vm._t("default"),
                ],
                2
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }