<template>
  <div class="app-blocked-info rounded-lg p-3 shadow-md" :class="classByType">
    <div class="flex flex-col justify-between">
      <div class="flex justify-center w-full">
        <sun-media-icon-dark class="w-32 h-32" />
      </div>

      <div class="font-bold">{{ title }}</div>
      <div>{{ message }}</div>
      <sun-button class="mt-3" @click="reloadPage()">Reload</sun-button>
    </div>
  </div>
</template>

<script>
import classColors from '@sunmedia/sun-ui/src/utils/classColors';
import SunMediaIconDark from '@/components/icons/SunMediaIconDark.vue';

export default {
  name: 'AppBlockedInfo',
  components: { SunMediaIconDark },
  props: {
    title: {
      type: String,
      default: () => 'APP Blocked',
    },
    message: {
      type: String,
      default: () => 'Unknown message',
    },
  },
  computed: {
    classByType() {
      return `${classColors('bg', 'yellow', 200)} ${classColors('text', 'yellow', 900)}`;
    },
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>

<style scoped>
.app-blocked-info {
  width: 30%;
  text-align: center;
  box-sizing: border-box;
}

@media (max-width: 1090px) {
  .app-blocked-info {
    width: 40%;
    text-align: center;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .app-blocked-info {
    width: 60%;
    text-align: center;
    box-sizing: border-box;
  }
}

@media (max-width: 640px) {
  .app-blocked-info {
    width: 100%;
    text-align: center;
    box-sizing: border-box;
  }
}
</style>
