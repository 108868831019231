<template>
  <sun-sidebar-item v-if="hasPermission" :active="getState" :force-children-state="getChildrenState()">
    <router-link :to="to" @click.native.capture="handleClick">
      <div class="flex">
        <div class="mr-2"><slot name="icon"></slot></div>
        <slot></slot>
      </div>
    </router-link>
    <template v-if="hasChildren" #children>
      <slot name="children"></slot>
    </template>
  </sun-sidebar-item>
</template>

<script>
import { mapGetters } from 'vuex';
import { SunSidebarItemStates } from '@sunmedia/sun-ui/src/utils';
import { USER } from '@/store/modules/auth/keys';
import isUserRole from '@/utils/user/isUserRole';

export default {
  name: 'SunSidebarNavItem',
  props: {
    to: {
      type: Object,
      required: true,
      validator: value => !!value?.name,
    },
  },
  computed: {
    ...mapGetters({
      user: USER,
    }),
    hasChildren() {
      return !!this.$slots.children;
    },
    activeRoutes() {
      return this.$route.matched.map(r => r.name).filter(r => !!r);
    },
    hasRoleRestrictions() {
      return !!this.requiredRoles?.length;
    },
    requiredRoles() {
      return this.to?.meta?.rolesAccepted || [];
    },
    userHasRequiredRole() {
      const contextId = this.$route.matched.find(r => r?.meta?.contextSecure)?.meta?.contextSecure;
      return contextId ? isUserRole(this.user, this.requiredRoles, contextId) : true;
    },
    hasPermission() {
      return !this.hasRoleRestrictions || this.userHasRequiredRole;
    },
    getState() {
      return this.isActiveState(this.to, this.$route.name);
    },
  },
  methods: {
    getChildrenState() {
      return this.activeRoutes.includes(this.to?.name) ? SunSidebarItemStates.OPEN : SunSidebarItemStates.CLOSED;
    },
    handleClick(event) {
      if (this.hasChildren) {
        event.preventDefault();
      }
    },
    isActiveState(route, name) {
      const areSame = route?.name === name;
      let hasChildren = false;
      if (!areSame && route?.children?.length) {
        const results = [];
        route.children.forEach(r => results.push(this.isActiveState(r, name)));

        hasChildren = results.includes(true);
      }

      return areSame || hasChildren;
    },
  },
};
</script>
