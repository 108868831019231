/** @typedef {string} ReportStatus */

/**
 * @readonly
 * @enum {ReportStatus}
 */
export default {
  CREATED: 'CREATED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  RUNNING: 'RUNNING',
};
