import { TYPES_ACTIONS } from '@/model/shared/typesActions';

export const management = Object.freeze({
  module: 'MANAGEMENT',
  actions: {
    show: TYPES_ACTIONS.SHOW,
    showList: TYPES_ACTIONS.SHOW_LIST,
    create: TYPES_ACTIONS.CREATE,
    edit: TYPES_ACTIONS.EDIT,
    delete: TYPES_ACTIONS.DELETE,
  },
  user: {
    name: 'USER',
    show: 'USER_SHOW',
    showList: 'USER_SHOW_LIST',
    create: 'USER_CREATE',
    edit: 'USER_EDIT',
    delete: 'USER_DELETE',
  },
  client: {
    name: 'CLIENT',
    show: 'CLIENT_SHOW',
    showList: 'CLIENT_SHOW_LIST',
    create: 'CLIENT_CREATE',
    edit: 'CLIENT_EDIT',
    delete: 'CLIENT_DELETE',
  },
  publisher: {
    name: 'PUBLISHER',
    show: 'PUBLISHER_SHOW',
    showList: 'PUBLISHER_SHOW_LIST',
    create: 'PUBLISHER_CREATE',
    edit: 'PUBLISHER_EDIT',
    delete: 'PUBLISHER_DELETE',
  },
  advertiser: {
    name: 'ADVERTISER',
    show: 'ADVERTISER_SHOW',
    showList: 'ADVERTISER_SHOW_LIST',
    create: 'ADVERTISER_CREATE',
    edit: 'ADVERTISER_EDIT',
    delete: 'ADVERTISER_DELETE',
  },
  site: {
    name: 'SITE',
    show: 'SITE_SHOW',
    showList: 'SITE_SHOW_LIST',
    create: 'SITE_CREATE',
    edit: 'SITE_EDIT',
    delete: 'SITE_DELETE',
  },
  campaign: {
    name: 'CAMPAIGN',
    show: 'CAMPAIGN_SHOW',
    showList: 'CAMPAIGN_SHOW_LIST',
    create: 'CAMPAIGN_CREATE',
    edit: 'CAMPAIGN_EDIT',
    delete: 'CAMPAIGN_DELETE',
  },
  product: {
    name: 'PRODUCT',
    show: 'PRODUCT_SHOW',
    showList: 'PRODUCT_SHOW_LIST',
    create: 'PRODUCT_CREATE',
    edit: 'PRODUCT_EDIT',
    delete: 'PRODUCT_DELETE',
  },
  lineInsertion: {
    name: 'LINE_INSERTION',
    show: 'LINE_INSERTION_SHOW',
    showList: 'LINE_INSERTION_SHOW_LIST',
    create: 'LINE_INSERTION_CREATE',
    edit: 'LINE_INSERTION_EDIT',
    delete: 'LINE_INSERTION_DELETE',
  },
  format: {
    name: 'FORMAT',
    show: 'FORMAT_SHOW',
    showList: 'FORMAT_SHOW_LIST',
    create: 'FORMAT_CREATE',
    edit: 'FORMAT_EDIT',
    delete: 'FORMAT_DELETE',
  },
  position: {
    name: 'POSITION',
    show: 'POSITION_SHOW',
    showList: 'POSITION_SHOW_LIST',
    create: 'POSITION_CREATE',
    edit: 'POSITION_EDIT',
    delete: 'POSITION_DELETE',
  },
  platform: {
    name: 'PLATFORM',
    show: 'PLATFORM_SHOW',
    showList: 'PLATFORM_SHOW_LIST',
    create: 'PLATFORM_CREATE',
    edit: 'PLATFORM_EDIT',
    delete: 'PLATFORM_DELETE',
  },
});
