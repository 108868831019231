<template>
  <transition name="fade" mode="out-in">
    <div v-if="showAlert" class="platform-status-modal">
      <platform-list-status-alert
        variant="warn"
        :platforms-done="platformsDone"
        :platforms-waiting="platformsWaiting"
        :platforms-error="platformsError"
        @click="close"
      />
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import PlatformListStatusAlert from '@/components/molecules/modules/thirdParty/platform/PlatformListStatusAlert.vue';
import { getConnectedPlatforms } from '@/services/modules/ThirdParty/platforms';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import { ACTIVE_CLIENT } from '@/store/modules/context/keys';
import { UPDATE_USER_SETTINGS_REQUEST } from '@/store/modules/settings/keys';
import AVAILABLE_SETTINGS from '@/model/shared/user/availableSettings';
import { dateWithoutTimeFormat } from '@/utils/dateTime/date';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';
import { SECTION_STATUS } from '@/model/shared/sectionStatus';
import { Toast } from '@/model/shared/Toast';
import { TODAY } from '@/utils/dateTime/today';
import getDateUTC from '@/utils/dateTime/getDateUTC';
import { CREATE_TOAST } from '@/store/modules/toast/keys';

export default {
  name: 'PlatformStatusModal',
  components: { PlatformListStatusAlert },
  props: {
    propSettings: {
      type: [Object, Array],
      require: true,
      default: () => null,
    },
  },
  data() {
    return {
      keySettings: 'showPlatformAlert',
      platformsDone: null,
      platformsWaiting: null,
      platformsError: null,
      isLoading: true,
      isOpen: true,
      userSettings: null,
    };
  },
  computed: {
    ...mapGetters({
      activeClient: ACTIVE_CLIENT,
    }),
    showAlert() {
      if (this.userSettings && this.userSettings.hasContext(AVAILABLE_SETTINGS.SHOW_PLATFORM_ALERT)) {
        const configAlert = this.userSettings.getContext(AVAILABLE_SETTINGS.SHOW_PLATFORM_ALERT);
        if (!configAlert.show && dateWithoutTimeFormat(getDateUTC(TODAY)) === configAlert.date) {
          return false;
        }
      }

      if (this.isOpen && !this.isLoading && !this.hasPlatformInfo) {
        this.loadPlatforms();
      }

      return this.isOpen && !this.isLoading && this.hasPlatformInfo;
    },
    hasPlatformInfo() {
      return !!this.platformsDone && !!this.platformsWaiting && !!this.platformsError;
    },
  },
  async created() {
    await this.loadSettings();
  },
  methods: {
    ...mapActions({
      updateUserSettings: UPDATE_USER_SETTINGS_REQUEST,
      createToast: CREATE_TOAST,
    }),
    async loadSettings() {
      this.isLoading = true;
      const settings = this.propSettings;
      if (!settings.hasContext(AVAILABLE_SETTINGS.SHOW_PLATFORM_ALERT)) {
        const showAlert = {
          show: true,
          date: dateWithoutTimeFormat(getDateUTC(TODAY)),
        };
        const newSettings = settings.clone();
        newSettings.setContext(AVAILABLE_SETTINGS.SHOW_PLATFORM_ALERT, showAlert);
        await this.updateUserSettings(newSettings);
      }
      this.userSettings = settings;
      this.isLoading = false;
    },
    async loadPlatforms() {
      this.isLoading = true;
      try {
        const params = new QueryParamsBuilder();
        params.addFilter('client.id', this.activeClient.id);
        const { data } = await getConnectedPlatforms(params, { preventKey: 'getPlatformFromModalAlert' });
        this.platformsDone = await this.mapStatusPlatform(data, 'OK');
        this.platformsWaiting = await this.mapStatusPlatform(data, 'REPORT_PENDING');
        this.platformsError = await this.mapStatusPlatform(data, 'WARNING');
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.sectionStatus = SECTION_STATUS.ERROR;
          await this.createToast(Toast.error(`We can not get platforms`, error));
        }
      }
      this.isLoading = false;
    },
    mapStatusPlatform(data, status) {
      return data.filter(platform => platform.status.id === status).map(platform => platform.name);
    },
    close() {
      this.isOpen = false;
      const showAlert = {
        show: false,
        date: dateWithoutTimeFormat(getDateUTC(TODAY)),
      };
      const newSettings = this.userSettings.clone();
      newSettings.setContext(AVAILABLE_SETTINGS.SHOW_PLATFORM_ALERT, showAlert);
      this.updateUserSettings(newSettings);
    },
  },
};
</script>

<style scoped>
.platform-status-modal {
  position: absolute;
  top: 0;
  left: 30%;
  width: 40%;
  text-align: center;
  box-sizing: border-box;
  z-index: 40;
}

@media (max-width: 768px) {
  .platform-status-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    z-index: 40;
  }
}
</style>
