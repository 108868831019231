<template>
  <wait-toast :status="toastStatus" :value="hasToast" @close="closeReportToast()">
    <template #default="{}">
      <p v-if="isLoading" class="text-gray-600 text-left">
        The report is being generated for {{ clientName }} ({{ contextName }}), please wait.
      </p>
      <template v-else>
        <p class="text-gray-600 text-left">
          Requested analytics report for {{ clientName }} ({{ contextName }}) is ready.
        </p>
        <div
          class="flex w-full min-h-full justify-start items-center text-gray-700 font-bold relative bottom-0 text-sm"
        >
          <span
            class="p-2 cursor-pointer text-orange-600"
            :class="{ 'cursor-not-allowed': isFileDownloading }"
            @click.prevent.once="downloadReport()"
          >
            Download Report
          </span>
        </div>
      </template>
    </template>
  </wait-toast>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ACTIVE_CLIENT, ACTIVE_CONTEXT } from '@/store/modules/context/keys';
import {
  ANALYTICS_REPORT_TOAST,
  DELETE_ANALYTICS_REPORT_TOAST,
  SET_ANALYTICS_REPORT_TOAST_FINISHED,
} from '@/store/modules/analyticsReportToast/keys';
import WaitToast from '@/components/atoms/WaitToast';
import ReportStatus from '@/model/shared/analytics/ReportStatus.js';
import fileDownload from '@/utils/fileDownload';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { CONTEXTS } from '@/model/shared/contexts';
import { LOADING_TOAST_STATUS } from '@/model/shared/LoadingToastStatus';
import apiRequest from '@/utils/apiRequest';

export default {
  name: 'AnalyticsReportToast',
  components: {
    WaitToast,
  },
  data: () => ({
    MAX_RETRIES: 6,
    remainingRetries: 0,
    isFileDownloading: false,
  }),
  computed: {
    ...mapGetters({
      analyticsReportToast: ANALYTICS_REPORT_TOAST,
      activeClient: ACTIVE_CLIENT,
      activeContext: ACTIVE_CONTEXT,
    }),
    hasRemainingRetries() {
      return this.remainingRetries > 0;
    },
    tryTimeout() {
      // we add 1 because we want that the minimum value is 1
      const increment = this.MAX_RETRIES + 1 - this.remainingRetries;
      return 10000 * increment;
    },
    hasToast() {
      return !!this.analyticsReportToast?.id;
    },
    clientId() {
      return this.analyticsReportToast?.client?.id || '';
    },
    clientName() {
      return this.analyticsReportToast?.client?.name || '';
    },
    contextName() {
      return CONTEXTS[this.analyticsReportToast?.client?.type]?.name;
    },
    isLoading() {
      return this.analyticsReportToast.waiting;
    },
    reportId() {
      return this.analyticsReportToast.id;
    },
    test() {
      return this.analyticsReportToast;
    },
    filename() {
      return `${this.reportId}.${this.analyticsReportToast.format}`;
    },
    reportEndpoints() {
      return this.analyticsReportToast?.reportEndpoints;
    },
    errorMsg() {
      return this.hasRemainingRetries
        ? 'An error occurred while generating the report'
        : `We couldn't get the report. Try again later`;
    },
    toastStatus() {
      return this.analyticsReportToast.waiting ? LOADING_TOAST_STATUS.LOADING : LOADING_TOAST_STATUS.SUCCESS;
    },
  },
  watch: {
    hasToast: 'waitingReportComplete',
  },
  mounted() {
    if (this.hasToast) {
      this.waitingReportComplete();
    }
  },
  methods: {
    ...mapActions({
      setToastAsCompleted: SET_ANALYTICS_REPORT_TOAST_FINISHED,
      deleteReportToast: DELETE_ANALYTICS_REPORT_TOAST,
      createToast: CREATE_TOAST,
    }),
    closeReportToast(error) {
      this.deleteReportToast();
      if (error) {
        this.createToast(Toast.error('Analytics', this.errorMsg));
      }
    },
    async waitingReportComplete(hasNewReport, hasOldReport) {
      const newReportIncoming = hasNewReport && !hasOldReport;
      if (newReportIncoming) {
        this.resetValues();
      }

      setTimeout(this.checkIsComplete, this.tryTimeout);
    },

    async checkIsComplete() {
      if (this.hasRemainingRetries) {
        apiRequest(async () => {
          const status = await this.reportEndpoints?.getReportStatus(this.clientId, this.reportId);
          const { FAILED, COMPLETED } = ReportStatus;

          if (status === COMPLETED || status === FAILED) {
            if (status === COMPLETED) {
              this.setToastAsCompleted();
            } else {
              this.closeReportToast(true);
            }
          } else {
            this.quitRemainingRetry();

            if (this.hasRemainingRetries) {
              setTimeout(this.checkIsComplete, this.tryTimeout);
            } else {
              this.closeReportToast(true);
            }
          }
        }).catch(error => {
          this.closeReportToast(error);
        });
      }
    },
    resetValues() {
      this.remainingRetries = this.MAX_RETRIES;
    },
    quitRemainingRetry() {
      const next = this.remainingRetries - 1;
      this.remainingRetries = next >= 0 ? next : 0;
    },
    async downloadReport() {
      try {
        this.isFileDownloading = true;
        const { file, mineType } = await this.reportEndpoints?.getReport(this.clientId, this.reportId);
        fileDownload(file, this.filename, mineType);
      } catch (error) {
        this.closeReportToast(error);
      } finally {
        this.isFileDownloading = false;
        this.closeReportToast();
      }
    },
  },
};
</script>
