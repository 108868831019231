<template>
  <transition name="fade" mode="out-in">
    <div class="app-blocked-modal">
      <app-blocked-info :title="title" :message="message" />
    </div>
  </transition>
</template>

<script>
import AppBlockedInfo from '@/components/organisms/shared/appBlocked/AppBlockedInfo.vue';

export default {
  name: 'AppBlocked',
  components: { AppBlockedInfo },
  props: {
    title: {
      type: String,
      default: () => 'APP Blocked',
    },
    message: {
      type: String,
      default: () => 'Unknown message',
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.app-blocked-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1010;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
