var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
    _c(
      "div",
      { staticClass: "app-blocked-modal" },
      [
        _c("app-blocked-info", {
          attrs: { title: _vm.title, message: _vm.message },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }